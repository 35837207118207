import React, { useEffect } from "react"
import TestimonialsPage from "../components/v1/pages/testimonials";
import { navigate } from "gatsby";
import { RouteLinks } from "../utils/route-links";

const Testimonials = () => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <TestimonialsPage />;
  } else {
    useEffect(() => {
      navigate(RouteLinks["404"]);
    }, []);

    return null;
  }
};

export default Testimonials;
